import React from "react";
import Logo from "./Logo";
import { useStateContext } from "../utils/web3";
import cwButton from "../assets/Connect Button.png"
import dwButton from "../assets/Disconnect Button.png"

function Header() {
  const { isConnected, disconnectWallet, connectWallet } = useStateContext();
  return (
    <div className="flex flex-row items-start w-full justify-between p-10 bg-transparent pb-0 z-20 h-1/5">
      <div className="flex h-3/6 md:h-5/6 w-1/3" >
        <Logo></Logo>
      </div>
      <div className="flex flex-row-reverse h-2/6 md:h-3/6 items-center md:w-2/3 header-container">
        {
          isConnected ? 
          (
            <button onClick={disconnectWallet} className="flex h-full md:mx-3 header-item">
              <img src={dwButton} alt="" className="h-full"/>
            </button>
          )
          :
          (
            <button onClick={connectWallet} className="flex h-full md:mx-3 header-item">
              <img src={cwButton} alt="" className="h-full w-auto"/>
            </button>
          )
        }
      </div>

    </div>
  );
  
}

export default Header;