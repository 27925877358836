import React from "react";
import logoIcon from "../assets/Logos.png"

function Logo() {
  return (
    <img src={logoIcon} alt="Keys" className="h-full w-auto"/>
  );
}

export default Logo;
