/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Background from "./Background";
import {MINT_VALUE, MAX_SUPPLY, useStateContext} from "../utils/web3";
import {Alert, AlertTitle, Dialog, Snackbar} from '@mui/material';
import GoBack from "../assets/Go-Back.png"
import notAcrive from "../assets/Mint-Deshabilitado.png"
import mintButton from "../assets/Mint Button.png"
import moreButton from "../assets/More Button.png"
import lessButton from "../assets/Less Button.png"
import loader from "../assets/loader.gif"
import { isMobile } from "react-device-detect";

function Mint() {
  const {
    account,
    mint,
    isMintOpen,
    isSoldOut,
    supply
  } = useStateContext();
  const [amount, setAmount] = useState<number>(1);
  const [isActive, setIsActive] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [isSold, setIsSold] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNotReady, setIsNotReady] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (account) {
      getData()
      supply().then((supply: number) => setTotalSupply(supply))
    } else {
      setIsActive(false);
    }
  }, [ account ]);

  const getData = async () => {
    const isSold = await isSoldOut();
    if ( isSold ) {
      setIsSold(true)
    } else {
      if (!await isMintOpen()) {
        setIsNotReady(true)
      } else {
         setIsActive(true)
      }
    }
  }

  const handlePlus = async () => {
    if (isActive && amount < 10 && amount + totalSupply < MAX_SUPPLY) {
        setAmount(amount+1)
    }
  }

  const handleMinus = () => {
    if (isActive && amount > 1) {
      setAmount(amount-1)
    }
  }

  const mintToken = async () => {
    setIsLoading(true);
    let tx = "";
    try {
      tx = await mint(amount);
    } catch(e) {
      console.log(e);
      setIsLoading(false);
      setOpenAlert(true);
    }
    if(tx) {
      setIsLoading(false);
      setIsSuccess(true);
      setTransactionHash(tx);
      setAmount(1);
    }
  }

  return (
    <>
      <div className={`flex flex-col justify-start items-center w-full h-4/5 z-10 pb-20 ${isMobile ? '' : 'pt-32'}`}>
        <div style={{backgroundColor: "#000000a1", borderRadius: "20px", padding: "3px 5%"}}>
        <div style={{fontSize: "35px"}} className="text-white lowercase text-center leading-none">
          GET YOUR <br></br> MIGRATION PASS
        </div>
        <div className="flex flex-row items-center" style={{justifyContent: "space-around"}}>
          <button onClick={handleMinus}>
            <img style={{height: "35px"}} src={lessButton} />
          </button>
          <div style={{fontSize: "85px", paddingBottom: "30px"}} className="text-white lowercase">
            {amount}
          </div>
          <button onClick={handlePlus}>
            <img style={{height: "35px"}} src={moreButton} />
          </button>
        </div>
        <div className="text-6xl md:text-xl text-white lowercase secondary-text leading-tight">
          COST: {MINT_VALUE} ETH + GAS FEES
        </div>
        <div className="text-6xl md:text-xl text-white lowercase secondary-text leading-tight">
          TOTAL COST: {(MINT_VALUE * (amount as any)).toFixed(2)} ETH + GAS FEES
        </div>
        <div className="text-6xl md:text-xl text-white lowercase secondary-text leading-tight mb-3">
          MAX SUPPLY: 555
        </div>
        </div>
        {
          !isActive &&
          <button onClick={() => {}} className="mint-button">
              <img style={{height: "70px"}} src={notAcrive} alt="" className="h-full w-auto"/>
          </button>
        }
        {
          isActive &&
          (
            loading ?
            <img src={loader} alt="" className="mint-button loader"/>
            :
            <button onClick={mintToken} className="mint-button">
                <img height="70px" src={mintButton} alt="" className="w-auto"/>
            </button>
          )
        }
      </div>
      <Dialog open={isSold} onClose={() => setIsSold(false)} PaperProps={{
        style: {
          padding: "40px",
          backgroundColor: 'black',
          boxShadow: 'none',
        },
      }}>
        <div className="flex flex-col items-center">
          <div className="text-7xl text-white lowercase secondary-text">
            SOLD OUT
          </div>
          <button onClick={() => setIsSold(false)} className="mint-button">
              <img style={{height: "70px"}} src={GoBack} alt="" className="h-full w-auto"/>
          </button>
        </div>
      </Dialog>
      <Snackbar message="Here is snackbar message" open={openAlert} autoHideDuration={30000} onClose={() => {setOpenAlert(false)}}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Ups there was an error sending your transaction. Please try again later
        </Alert>
      </Snackbar>
      <Dialog open={isSuccess} onClose={() => setIsSuccess(false)} PaperProps={{
        style: {
          padding: "40px",
          backgroundColor: 'black',
          boxShadow: 'none',
        },
      }}>
          <div className="flex flex-col items-center">
            <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer" className="w-full">
              <div className="text-7xl md:text-5xl text-white lowercase secondary-text">
                mint successfully
              </div>
            </a>
            <button onClick={() => setIsSuccess(false)} className="mint-button">
                <img src={GoBack} alt="" className="h-full w-auto"/>
            </button>
          </div>
      </Dialog>
      <Dialog open={isNotReady} onClose={() => setIsNotReady(false)} PaperProps={{
        style: {
          padding: "40px",
          backgroundColor: 'black',
          boxShadow: 'none',
        },
      }}>
          <div className="flex flex-col items-center">
            <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer" className="w-full">
              <div className="text-7xl md:text-5xl text-white lowercase secondary-text">
                mint is not ready
              </div>
            </a>
            <button onClick={() => setIsNotReady(false)} className="mint-button">
                <img src={GoBack} alt="" className="h-full w-auto"/>
            </button>
          </div>
      </Dialog>
      <Background></Background>
    </>
  )
}

export default Mint;