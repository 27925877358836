import React from 'react';
import Header from './components/Header';
import Mint from './components/Mint';
import './App.css';
import {StateProvider} from './utils/web3'

function App() {
  return (
    <StateProvider>
      <div className="w-screen back overflow-x-hidden">
        <Header></Header>
        <Mint></Mint>
      </div>
    </StateProvider>
  );
}

export default App;
